@tailwind base;
@tailwind components;
@tailwind utilities;


@import './base/colors';
@import './components/buttons';
@import './components/calendar';
@import './components/listDays';

html,
body {
  background-color: $secondary;
}