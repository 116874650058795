$cdark: #393E46;
$secondary: #EEEEEE;
$primary: #3BB8F6;
$third: #222831;


.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-third {
  background-color: $third;
}