.btn-primary {
    margin: 0.25rem;
    cursor: pointer;
    border-radius: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-align: center;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    color: white;
    background: $primary;
    background: linear-gradient(90deg, darken($primary, 10%) 0%, darken($primary, 20%) 100%);

    &:hover {
        background: linear-gradient(90deg, darken($primary, 20%) 0%, darken($primary, 10%) 100%);
    }
}